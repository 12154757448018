@import 'constants';
@import 'joke';

// Bulma styles
@import '../../node_modules/bulma/bulma.sass';

// Mixins
@mixin full-height {
  min-height: 100vh;
}

@mixin parallax {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.65;

  .card {
    width: 50%;

    h1 {
      font-weight: normal;
      font-style: italic;
      display: inline;
    }
  }

  @media only screen and (max-width: 765px) {
    .card {
      width: 85%;
    }
    background-attachment: unset;
  }
}

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin drop-shadow {
  -webkit-box-shadow: 0px 0px 12px 5px rgba(27, 27, 27, 0.7);
  box-shadow: 0px 0px 12px 5px rgba(27, 27, 27, 0.7);
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

#home {
  @include full-height;

  background: url('../Home/images/photo-1497250681960-ef046c08a56e.jpeg');
  background-size: cover;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 60vh 40vh;
  justify-items: center;
  font-size: calc(10px + 2vmin);
  color: black;

  @include parallax;
  background-attachment: unset;

  p {
    align-self: flex-end;
    margin: 0 10px;
    font-style: italic;
    font-weight: 500;
    font-weight: bolder;
  }

  div {
    align-self: flex-end;
  }

  svg {
    align-self: flex-end;
    color: pink;
    -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
    filter: drop-shadow(-1px 3px 2px rgba(0, 0, 0, 0.7));
  }

  .glow {
    font-size: 80px;
    font-family: 'Homemade Apple', cursive;
    color: #fff;
    text-align: center;
    -webkit-animation: glow 1s ease-in-out infinite alternate;
    -moz-animation: glow 1s ease-in-out infinite alternate;
    animation: glow 1s ease-in-out infinite alternate;
  }

  @keyframes glow {
    from {
      text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e60073, 0 0 40px #e60073, 0 0 50px #e60073, 0 0 60px #e60073,
        0 0 70px #e60073;
    }
    to {
      text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6,
        0 0 70px #ff4da6, 0 0 80px #ff4da6;
    }
  }
}

nav {
  width: 100%;
  background-color: $neutral;
  color: $base-dark;
}

section#about {
  @include drop-shadow;

  line-height: 2em;

  .hero.is-fullheight {
    padding: 5% 10%;
  }

  .content {
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 10px;
    justify-items: center;
    align-items: center;

    @media only screen and (max-width: 650px) {
      grid-template-columns: 100%;
    }
  }

  #profile {
    max-width: 55%;
    border-radius: 55%;
    border: dotted $primary 4px;
  }

  #tech-list-container {
    width: 100%;

    #tech-list {
      display: flex;
      flex-direction: column;

      .tech {
        display: grid;
        grid-template-columns: 30% 70%;
        align-items: center;
      }
    }

    .tech {
      width: 100%;
    }
  }
}

#projects-header {
  background: url('../Projects/images/ricardo-resende-4eqOc39NCRQ-unsplash (1).jpg');
  @include full-height;
  @include parallax;
  @include center;
}

#projects-list {
  @include drop-shadow;

  .title.is-5 {
    margin: 0;
  }

  .content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgb(221, 255, 245);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  .card:hover .content {
    opacity: 1;
  }

  .modal-content {
    overflow-y: auto;
    overflow-x: hidden;
    width: 70vw;

    div.image .slider {
      height: 100% !important;
    }

    .image.is-square {
      position: unset;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .project-img {
      width: 100%;
    }

    &.box {
      padding: 0;
    }

    &.is-full-height {
      height: 90vh;
    }

    .tile.is-vertical.is-parent {
      display: unset;
    }
  }
}

#blogs-header {
  background: url('../Home/images/retrosupply-jLwVAUtLOAQ-unsplash.jpg');
  @include full-height;
  @include parallax;
  @include center;
}

#blogs-list {
  @include drop-shadow;

  img.blog-img {
    object-fit: fill;
  }
}

#contact-header {
  background: url('../Home/images/pavan-trikutam-71CjSSB83Wo-unsplash.jpg');
  @include full-height;
  @include parallax;
  @include center;
}

#contact {
  .box {
    width: 60%;
    margin: auto;
    form {
      text-align: left;
    }

    @media only screen and (max-width: 765px) {
      width: 95%;
    }
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer.footer {
  padding: 3rem 1.5rem 4rem;

  .extra {
    margin-bottom: 15px;

    .contact-item {
      margin: 0 20px;

      a {
        color: $base-dark;
      }
    }
  }
}
